const idPortalApi = "4b3fbeb96f68cc039425f91dc7033cbfc066e300";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://portorico.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "portorico.ma.gov.br",
  NomePortal: "Porto Rico do Maranhão",
  ufPortal: "MA",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 4, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/M3MBkPJzAYNeSGDW6`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11193.24577489717!2d-44.5958181488366!3d-1.8599019493575857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f79161be692dcb%3A0x20f5d7d1a1f6e336!2sPorto%20Rico%20do%20Maranh%C3%A3o%2C%20MA%2C%2065263-000!5e0!3m2!1spt-BR!2sbr!4v1722451780436!5m2!1spt-BR!2sbr",
};
